export const client: RECO.Mobile.Config.Client = (window["client"] = {
    title: "保定创发科技",
    techSupport: "技术支持:  上海瑞谷拜特软件技术有限公司",
    urlArgs: "v=180427",
    tel: "0312-7585555",
    /**
     * 作为区分同一域名下不同网页的缓存的关键字
     */
    appDomain: "biparkall_web",
    /**
     * 底层退出配置跳转地址
     */
    logoutRouter: `/login`,

    /**
     * 高德key
     */
    amap: {
        script: {
            // key: "5a416708b7ae8efb727ce39b85e6f2fa"
            key: "0a34cccde7c0e7f23266c9053b3b6adf", //13466686911 账号下的key
        },
    },

    app: { openBusinessBill: true, openAdminiApproval: true, openEnterpriseHome: true, openRecruit: true },
    ...window["client"],
});

export const server: RECO.Mobile.Config.Server = (window["server"] = {
    apiKey: {
        apiKey: "Bitech\\PC",
        secret: "44678314ba0efa0c",
    },
    apiVersion: 9,
    auth: {
        oauth2Url: "authorization/oauth2",
        autoLogin: !0,
        autoRefreshToken: !0,
        anonymousLogin: !0,
    },

    /**
     * 附件预览域名地址-永中平台
     */
    previewUrl: "https://ys.bitech.cn/dcs-preview/",
    /**
     * 是否开启附件预览配置
     */
    plugins: {
        attach: {
            onlineDoc: true,
        },
    },

    // 高德秘钥
    //  mapSecurityJsCode: "08c3f47814745dee575cdd5034ae9c8a",
    mapSecurityJsCode: "2ec40af0a8a8aa6896b7244329199abb", //13466686911 账号下的密钥

    rsa: {
        enable: true,
        publicKey: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXu1ms9TrhrMlIn3iv4g2Pw9Mfr9abdAB-TpDD65G98wjHZxgkZfhzUHE8BSsUDzIHfOiCd4RT5fZtTiop9bzwecgYCrAICjrVC-8ZWKf1RqRX4EXiISyEBHb4YAER_Rt2TQEWGPCi14ujDTA9qr1_YRUFfh52nrc-MfkfC12BwQIDAQAB`,
        privateKey: `MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBANe7Waz1OuGsyUifeK_iDY_D0x-v1pt0AH5OkMPrkb3zCMdnGCRl-HNQcTwFKxQPMgd86IJ3hFPl9m1OKin1vPB5yBgKsAgKOtUL7xlYp_VGpFfgReIhLIQEdvhgARH9G3ZNARYY8KLXi6MNMD2qvX9hFQV-Hnaetz4x-R8LXYHBAgMBAAECgYEAk6DxzRpEIQtM8xWuUS4weaFYaOa7xcWsUMMS7f_WzFX0PACg1MezLbZuxtQClsePRPCFbEK9m2xTJpnG4M_5apVIIuA5QhGPR5kimQMf1jeVYuiXJ2fOOyBza4ydfgRYCi1BhbakvDA5D0NDAT9HdV6Jfmv78QFF4THyKHGe5MECQQDrK88We3MbyZuLwkmEJlSPnP4AS8omCeamB5Gt_kPeRF2fqK0Bi8pfGqHtp8gPu1KQ7Nag7BJvOFDnL1UvlIH_AkEA6tbICd9yzaWYnoesc9P0bEEDH02R6dduzDkVC6Vni6v_KmbS5aZ_B4bV2nldNQiiDCrwulc2_iNO4Ug45SZ8PwJBAJQ-KWyipQz_KW4QKweCogtlizfkjqkr1JkUbGChx2Z08g-S5Hv1ArywrSKCgTppesKgiSsnpPc3KMiSrcAwfUECQAKiPAYYUnJJ_CczAFUgOGqKZB2SwiMsPlrd_QVvanTfjfIMyyq2LlCIS5eyqr_wg9i9FowQdFZvUPAn3jN7tgMCQHeAJZuIKpgCV2mWMQw0FgSf2_UmTjboUqbFf5d11KAW7iMj0ygHwt1bjALthKv7-ixdaAuY0URqZXYNMiAZT1o`,
    },
    webappurl: "https://www.bdcfkj.com/bduniversitypark-webapp/", // 后台地址
    appdownURL: "http://demo.bitech.cn/appdownbmobile", // app下载地址
    assetsUrl: "https://www.bdcfkj.com/bduniversitypark-mobileapi/", // 正在使用

    url: "https://www.bdcfkj.com/bduniversitypark-mobileapi/", // 正在使用

    ...window["server"],
});
