import { router } from "dva";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";

import { getParkCode, loadLazyModule } from "@reco-w/core-ui";

function RouterConfig({ history }: any) {
    return (
        <ConfigProvider locale={zhCN}>
            <router.Router history={history}>
                <router.Switch>
                    <router.Route path="/:parkcode/login" component={loadLazyModule(() => import(/* webpackChunkName: "login" */ "@reco-w/auth-login"))} />
                    <router.Route exact path="/:parkcode" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/home-home"))} />
                    <router.Route path="/:parkcode/activity" component={loadLazyModule(() => import(/* webpackChunkName: "activity" */ "@reco-w/activity-activity"))} />
                    <router.Route path="/:parkcode/circle" component={loadLazyModule(() => import(/* webpackChunkName: "circle" */ "@reco-w/circle-circle"))} />
                    <router.Route path="/:parkcode/article" component={loadLazyModule(() => import(/* webpackChunkName: "article" */ "@reco-w/article-article"))} />
                    <router.Route path="/:parkcode/my" component={loadLazyModule(() => import(/* webpackChunkName: "my" */ "@reco-w/my-my"))} />
                    <router.Route path="/:parkcode/policyo" component={loadLazyModule(() => import(/* webpackChunkName: "policy" */ "@reco-w/policy-policy"))} />
                    <router.Route path="/:parkcode/policy" component={loadLazyModule(() => import(/* webpackChunkName: "policy" */ "@reco-w/policy-newpolicy"))} />
                    <router.Route path="/:parkcode/resource/:roomname" component={loadLazyModule(() => import(/* webpackChunkName: "order-room" */ "@reco-w/order-room"))} />
                    <router.Route path="/:parkcode/survey" component={loadLazyModule(() => import(/* webpackChunkName: "survey" */ "@reco-w/survey-survey"))} />
                    <router.Route path="/:parkcode/impression" component={loadLazyModule(() => import(/* webpackChunkName: "impression" */ "@reco-w/impression-impression"))} />
                    <router.Route path="/:parkcode/problem" component={loadLazyModule(() => import(/* webpackChunkName: "problem" */ "@reco-w/problem-problem"))} />
                    <router.Route path="/:parkcode/workorder" component={loadLazyModule(() => import(/* webpackChunkName: "workorder" */ "@reco-w/workorder-workorder"))} />
                    <router.Route path="/:parkcode/market" component={loadLazyModule(() => import(/* webpackChunkName: "market" */ "@reco-w/market-market"))} />
                    <router.Route path="/:parkcode/product" component={loadLazyModule(() => import(/* webpackChunkName: "market-product" */ "@reco-w/market-product"))} />
                    <router.Route path="/:parkcode/heardsearch" component={loadLazyModule(() => import(/* webpackChunkName: "header-index" */ "@reco-w/layout-headersearch"))} />
                    <router.Route path="/:parkcode/share" component={loadLazyModule(() => import(/* webpackChunkName: "share" */ "@reco-w/share-share"))} />
                    <router.Route path="/:parkcode/msgreach" component={loadLazyModule(() => import(/* webpackChunkName: "msgreach" */ "@reco-w/msgreach-msgreach"))} />
                    <router.Route path="/:parkcode/enterprise" component={loadLazyModule(() => import(/* webpackChunkName: "msgreach" */ "@reco-w/enterprise-enterprise"))} />
                    <router.Route path="/:parkcode/recruit" component={loadLazyModule(() => import(/* webpackChunkName: "msgreach" */ "@reco-w/recruit-recruit"))} />
                    <router.Route path="/:parkcode/investment" component={loadLazyModule(() => import(/* webpackChunkName: "msgreach" */ "@reco-w/investment-investment"))} />
                    <router.Route
                        path="/:parkcode/adminiapproval"
                        component={loadLazyModule(() => import(/* webpackChunkName: "adminiapproval" */ "@reco-w/adminiapproval-adminiapproval"))}
                    />
                    <router.Route
                        path="/:parkcode/serviceentrance"
                        component={loadLazyModule(() => import(/* webpackChunkName: "serviceentrance" */ "@reco-w/serviceentrance-serviceentrance"))}
                    />
                    <router.Route
                        path="/:parkcode/propertyentrance"
                        component={loadLazyModule(() => import(/* webpackChunkName: "propertyentrance" */ "@reco-w/propertyentrance-propertyentrance"))}
                    />
                    {/*党建*/}
                    <router.Route path="/:parkcode/party" component={loadLazyModule(() => import(/* webpackChunkName: "activity" */ "@reco-w/party-party"))} />
                    {/*会议室预约屏-蓝色  meetingBlue/889056607897051136 */}
                    <router.Route path="/:parkcode/meetingBlue/:meetId" component={loadLazyModule(() => import(/* webpackChunkName: "meetingBlue" */ "@reco-w/meetingBlue-meetingBlue"))} />
                    {/*领导预约*/}
                    <router.Route path="/:parkcode/leadership/:leaderId" component={loadLazyModule(() => import(/* webpackChunkName: "leadership" */ "@reco-w/leadership-leadership"))} />

                    <router.Redirect exact to={`/${getParkCode()}`} />
                </router.Switch>
            </router.Router>
        </ConfigProvider>
    );
}

export default RouterConfig;
